<template>
  <iframe
    style="border: 0"
    width="100%"
    :height="screenHeight"
    scrolling="no"
    :src="`/gzpdf/web/viewer.html?file=${url}`"
  ></iframe>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      screenHeight: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.screenHeight = window.innerHeight;
    },
  },
};
</script>
