<template>
  <div style="background-color: #ededed; min-height: 100vh; padding: 5px">
    <el-card>
      <img style="width: 100%" :src="gzUrl" alt="" />
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      gzUrl: "",
    };
  },
  created() {
    this.gzUrl = this.$route.query.url;
  },
  methods: {},
};
</script>
