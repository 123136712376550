import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";
import cq from "../views/cq.vue";
import from from "../views/from.vue";
import none from "../views/none.vue";
import cqjz from "../views/cqjz.vue";
import jx from "../views/jx.vue";
import ah from "../views/ah/ah.vue";
import ahqr from "../views/ah/ahqr.vue";
import gz from "../views/gz/index.vue";
import gzContent from "../views/gz/content.vue";
import sh from "../views/sh/index.vue";





// 报错解决
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "index",
		component: index,
		meta: {
			title: "广西壮族自治区人社表单验证",
		},
	},
	{
		path: "/cq",
		name: "cq",
		component: cq,
		meta: {
			title: "重庆智慧人社表单验证",
		},
	},
	{
		path: "/from",
		name: "from",
		component: from,
		meta: {
			title: "",
		},
	},
	{
		path: "/none",
		name: "none",
		component: none,
		meta: {
			title: "",
		},
	},
	{
		path: "/cqjz",
		name: "cqjz",
		component: cqjz,
		meta: {
			title: "",
		},
	},
	{
		path: "/ah",
		name: "ah",
		component: ah,
		meta: {
			title: "validatePZ",
		},
	},
	{
		path: "/ahqr",
		name: "ahqr",
		component: ahqr,
		meta: {
			title: "",
		},
	},
	{
		path: "/sh",
		name: "sh",
		component: sh,
		meta: {
			title: "etax.shanghai.chinatax.gov.cn",
		},
	},
	{
		path: "/gz",
		name: "gz",
		component: gz,
		meta: {
			title: "rsrc.gzsrs.cn",
		},
	},
	{
		path: "/gzContent",
		name: "gzContent",
		component: gzContent,
		meta: {
			title: "rsrc.gzsrs.cn",
		},
	},
	{
		path: "/jx",
		name: "jx",
		component: jx,
		meta: {
			title: "个人权益单",
		},
	},




];
// title

const router = new VueRouter({
	// mode: 'history',
	routes,
	// 跳转页面后 回到顶部
	scrollBehavior() {
		return {
			x: 0,
			y: 0,
		};
	},
});

export default router;

//title
const defaultTitle = "";
router.beforeEach((to, from, next) => {
	document.title = to.meta.title ? to.meta.title : defaultTitle;
	next();
});
