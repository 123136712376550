<template>
  <div></div>
</template>
<script>
export default {
  data() {
    return {
      url:
        "https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-09-30/59d95b9cbb190334b906124c5b143a81.pdf",
      isShow: false,
      code: null,
    };
  },
  created() {
    this.getUrlCode("code");
  },
  methods: {
    // gzClick() {
    //   window.location.href = this.url
    //   alert(111111111111)
    //   return
    //   // this.$router.push({ name: "gzContent", query: { url: this.url } });
    // },
    getUrlCode(variable) {
      var query = window.location.href.substring(1);
      var vars = query.split("?");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          this.code = pair[1];
        }
      }

      setTimeout(() => {
        window.location.href = this.url;
      }, 500);
    },
  },
};
</script>