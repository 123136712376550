<template>
  <!-- 贵州页面 -->
  <div>
    <div
      style="
        background-color: #1fa0fe;
        padding: 10px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 3px;
      "
      @click.stop="gzClick()"
    >
      <img
        style="height: 18px; width: 18px; margin-right: 5px"
        src="../../assets/xiazai.png"
        alt=""
      />
      <span>下 载</span>
    </div>
    <div style="border: 2px solid #999a9e;">
      <pdf-viewer :url="url"></pdf-viewer>
    </div>
  </div>
</template>

<script>
import PdfViewer from "@/components/gzPdfViewer.vue";
export default {
  components: {
    PdfViewer,
  },
  data() {
    return {
      file: "",
      picUrl: "",
      url: "",
      isShow: false,
      code: null,
      isWeixin: false,
    };
  },
  created() {
    this.getUrlCode("code");
    this.getCode();
    this.checkPlatform();
    if (this.isAndroid == true) {
      document.title = "";
    }
  },
  methods: {
    getCode() {
      let data = {
        fwm: this.code,
      };
      // 转换对象为查询字符串
      const urlParams = new URLSearchParams(data);
      // const url = `https://admin.atqf.cn/prod-api/atqf/shbxjfzm/fwm?${data}`;
      const url = `https://admin.atqf.cn/prod-api/atqf/gzShbxjfzm/fwm?${urlParams.toString()}`;
      const options = {
        method: "GET", // 请求方法
        headers: {
          "Content-Type": "application/json", // 设置请求头
        },
      };

      fetch(url, options)
        .then((response) => response.json())
        .then((res) => {
          console.log(res.data, "res.data");
          this.picUrl = res.data.img;
          this.url = res.data.path;
          this.file = res.data.file;
        })
        .catch((error) => console.error("Error:", error));
      return;
    },
    checkPlatform() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      this.isAndroid = userAgent.toLowerCase().indexOf("android") > -1;
      this.isIOS =
        (/iPad|iPhone|iPod/.test(userAgent) && !this.isAndroid) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
    },
    gzClick() {
      if (this.isIOS == true) {
        this.$router.push({ name: "gzContent", query: { url: this.picUrl } });
      } else {
        window.location.href = this.file;
      }
    },
    getUrlCode(variable) {
      var query = window.location.href.substring(1);
      var vars = query.split("?");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          this.code = pair[1];
        }
      }
    },
  },
};
</script>
